.adminLayout {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: '#FAFAFB';

    .appBar {
        width: 100%;
        border-bottom: 2px solid #EEECF9;
        height: var(--appBarHeight);

        // &.active {
        //             width:250px;
        //             background-color:pink;
        //             z-index: 999;
        //         }
    

    }

    .contentWrapper {
        display: flex;
        height: calc(100% - var(--appBarHeight));
        width: 100%;

        .sideBarOverlay {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            width: 0;
            transition: .2s ease-in-out;
            background-color: rgba(0, 0, 0, 0);

            &.active {
                width: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 999;
            }

            @media screen and (max-width: 576px) {
                display: block;
            }

        }

        .sideBar {
            width: var(--sideBarWidth);
            height: 100%;
            overflow: hidden;
            transition: .2s ease-in-out;
            box-shadow: 15px 15px 16px rgb(238, 236, 249, 0.16);
            font-size: 1.6rem;
            
        
            @media screen and (max-width: 576px) {
                position: fixed;
                top:0;
                left: 0;
                max-width: 80%;
                z-index: 999;
            }

        }
    
        .contentSection {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 20px;
            flex: 1;
            font-size: 1.6rem;

            .contentHeading{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .contentArea{
                width: 100%;
                height: auto;
                border-radius: 15px;
                padding: 10px;
                margin-top: 20px;
                // box-shadow: 0px 0px 6px #EEECF9;
                box-shadow: 0px 0px 6px #757582;
            }
        }
    }
}