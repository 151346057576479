.login_wrapper {
    height: 100vh;
    width: 100%;
    overflow: hidden;

    .banner_section {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .content_section_wrapper {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        
        .content_section {
            width: 100%;
            height: 100vh;
            // overflow-y: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .content_box {
                width: 100%;
                max-width: 500px;

                .logo_container {
                    width: 186px;
                    overflow: hidden;
                    margin: 0 auto 60px;
                    max-width: 50%;
                    

                    @media (max-width: 576px) {
                        min-width:120px;
                        max-width: 30%;
                        margin-bottom: 30px;
                        
                    }
                        
                }

                .page_content {
                    width: 100%;

                }
            }


        }
    }
}