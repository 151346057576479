.left_content_wrapper {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0, 0.5) 0%, rgba(0,0,0, 0.4) 100%), url("./../../../assets/images/bg/design-cover.jpg") ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}