@import "./theme/theme";

// Define a mixin for WebKit-based browsers' scrollbar
@mixin webkit-scrollbar($width: 12px, $track-color: #f1f1f1, $thumb-color: #888, $thumb-hover-color: #555) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 0;
    border: ($width - 4px) solid transparent; // Making thumb linear
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $thumb-hover-color;
  }
}

// For Firefox
@mixin firefox-scrollbar($width: thin, $thumb-color: #888, $track-color: #f1f1f1) {
  scrollbar-width: $width;
  scrollbar-color: $thumb-color $track-color;
}

// Applying the mixins globally
*,
*::before,
*::after {
  @include webkit-scrollbar();
  @include firefox-scrollbar();
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.2px;
}

body,
html {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
}


.MuiDayCalendar-weekDayLabel {
  font-size: 12px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #6B6D70 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  ;
  transition: background-color 5000s ease-in-out 0s;
  
}


.custom_menu_drop {
  
  background: #242432 !important
}