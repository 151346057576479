.wrapper {
    height: 80vh;
    width: 100%;
    padding: 70px 50px;

    .header {
   
        margin-bottom: 50px;
        height: 50px;
        font-size: 14px;
        align-items: center;

        .backicon{
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;
        }
    }

    .body {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: calc(80vh - 220px);

        .card {
            display: flex;
            flex-direction: column;
            min-width: 280px;
            width: 400px;
            gap: 40px;

            img {
                // width: 100%;
                align-self: center;
            }

            .scanwrapper {
                height: 100px;
                background-color: lightgray;
                padding: 20px;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                gap: 20px;
                background-color: #33353f;
                cursor: pointer;

                h3 {
                    font-size: 22px;
                }

                img {
                    height: 36px;
                    width: 36px;
                }
            }
        }

        .division {
            
            margin-bottom: 10px;
            font-size: 20px;
            display: grid;
            grid-template-columns: 1fr max-content 1fr;
            grid-column-gap: 1.5rem;
            align-items: center;
            width:200px;
            
    
    
            &::before,
            &::after {
                content: "";
                height: 1px;
                
                display: block;
                background-color: gray;
            }


            // &::before,
            // &::after {
            //     content: "";
            //     height: 1px;
            //     display: block;
            //     background-color: #C3C6CF;
                
            // }

        }

    }

    .qrcodetext {
        color: #D6D0D0;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        background-image: url("../../../");
    }
}

@media (max-width:450px) {
    .wrapper {
        padding: 30px 20px;

        .header{
            margin-bottom: 20px;
        }
    }
  
}