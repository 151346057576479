.appBarWrapper {
    display: flex;

    .logoSection {
        width: var(--sideBarWidth);
        height: var(--appBarHeight);
        transition: .2s ease-in-out;
        flex: none;
        display: flex;
        align-items: center;

        img {
            width: 200px;
            height: 55px;
            margin-left: 20px;
            object-fit: contain;
        }

        @media screen and (max-width: 576px) {
            width: 50px;
        }


        .logo {
            display: none;
        }


    }

    .appBar {
        width: 100%;
        height: var(--appBarHeight);
        padding: 23px 23px;
        display: flex;
        justify-content: space-between;

        .notification {
            display: flex;
            gap: 20px;
            align-items: center;

            .icon {
                width: 40px;
                height: 40px;
                background-color: rgb(128, 192, 224, 0.3);
                opacity: 50%;
                border-radius: 5px;
            }
        }


    }


}