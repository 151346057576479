.sidebarWrapper {

    .logo {
        display: none;
        width: var(--sideBarWidth);
        overflow: hidden;
        padding:0;
        
    }

    @media screen and (max-width:'576px') {
        .logo {
            display: block;
            padding: 0;

            img {
                width: 50%;
                object-fit: contain;
            }
        }
    }
}